.svg-section {
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: rotate(90deg);
    width: 2.5rem;
    height: auto;
    cursor: pointer;
  }
}
